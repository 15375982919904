body.cms_category,
body.cms_subcategory {

    #cms_subcategory_menu {
        width: 98%;
        margin: auto;
    }

    .main_ariane {
        .spcovid {
            color: #989898;
        }
    }

    // .title is used for the footer sections titles, so we need to exclude the #copyright div
    // cf support request https://wshopcloudcommerce.atlassian.net/browse/SW-14031
    .title:not(.footer_titles) {
        font-family: $primaryFont;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-align: center;
    }

    #cms_subcategory_menu {
        ul {
            display: flex;
            margin-bottom: 50px;
            gap: 80px;
            justify-content: center;
            align-content: center;
            align-items: center;

            .csc_menu_counter {
                display: none;
            }

            .item {
                display: initial;
                overflow: initial;
                width: auto;

                &.selected {
                    a {
                        text-decoration-line: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 3px;
                    }
                }

                a {
                    font-weight: 500;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-size: 12px;

                    &:hover {
                        text-decoration-line: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 3px;
                    }
                }
            }
        }
    }

    #content_previsu_cms_page {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 40px 20px;
        width: 98%;
        max-width: 1500px;
        margin: auto;

        .previsu_cms_page {
            width: 23.6%;

            img {
                width: 100%;
            }
        }

        .cms_page_previsu_items {
            a {
                text-decoration: none;
            }

            .cms_page_previsu_cat {
                color: #000;
                font-family: $primaryFont;
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;
                width: 100%;
                margin-bottom: 10px;
                margin-top: 13px;
            }

            .cms_page_previsu_title {
                color: #000;
                /* title/h4 */
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                width: 100%;
                max-width: 400px;
                text-decoration: none;
                margin-bottom: 13px;
            }

            .cms_page_previsu_btn {
                a {
                    color: #000;
                    font-family: $secondaryFont;
                    font-size: 16px;
                    font-style: italic;
                    font-weight: 400;

                    span {
                        text-decoration-line: underline;
                        text-decoration-thickness: 1px;
                        text-underline-offset: 2px;
                    }
                }
            }
        }
    }
}

.cms_page_content {
    display: block;
    width: 100%;
    max-width: 1480px;
    padding: 0 20px;
    margin: 20px auto;

    .cms-page-module-body {
        &.module-title {
            .cms-page-module-title {
                font-family: $primaryFont;
                font-size: 30px;
                line-height: 1;
                color: $black;
                margin-bottom: 25px;

                a {
                    color: $black;
                    text-decoration: none;
                }
            }
        }

        &.module-subtitle {
            .cms-page-module-subtitle {
                font-family: $primaryFont;
                font-size: 18px;
                line-height: 1;
                color: $black;
                margin-bottom: 25px;

                a {
                    color: $black;
                    text-decoration: none;
                }
            }
        }

        &.module-text {
            .cms-page-module-text {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.2;
                color: $black;

                div {
                    margin-bottom: 25px;
                }

                a {
                    color: $black;
                }

                ul {
                    list-style-type: disc;
                    list-style-position: inside;
                    margin: 10px 0;

                    li {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        &.module-cta {
            .cms-page-module-cta {
                font-family: $secondaryFont;
                font-size: 20px;
                font-weight: 400;
                font-style: italic;
                line-height: 1;
                color: $black;
                margin-bottom: 25px;

                & > div {
                    span,
                    a {
                        position: relative;
                        display: inline-block;
                        width: auto;
                        text-decoration: none;
                        color: var(--textcolor, $black);

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background-color: var(--textcolor, $black);
                            right: 0;
                            bottom: -2px;
                            margin: auto;
                            transition: width ease-in-out 0.5s;
                        }
                    }
                }

                &:hover {
                    & > div {
                        span,
                        a {
                            &:before {
                                width: 0%;
                            }
                        }
                    }
                }
            }
        }

        &.module-cta2 {
            .cms-page-module-cta2 {
                font-family: $primaryFont;
                font-size: 13px;
                font-weight: 500;
                font-style: normal;
                line-height: 1;
                color: $black;
                text-transform: uppercase;
                margin-bottom: 25px;

                & > div {
                    span,
                    a {
                        position: relative;
                        display: inline-block;
                        width: auto;
                        text-decoration: none;
                        padding: 20px 40px;
                        color: var(--textcolor, $white);
                        background-color: $black;
                        border: 1px solid var(--textcolor, $black);
                        transition: background ease 1s;

                        &:hover {
                            color: $black !important;
                            background-color: $white !important;
                            border: 1px solid $black;
                        }
                    }
                }
            }
        }
    }
}
body.customer {
    .wrapper_moncompte {
        .wrapper_mes_commandes {
            margin: -2px auto;

            .intro {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                width: 845px;
            }

            .content_commandes {
                .wrapper_menu_tab {
                    display: flex;
                    padding-top: 32px;
                    max-width: 851px;
                    width: 100%;
                    box-sizing: border-box;
                    margin-bottom: 41px;

                    .date_select {
                        position: relative;

                        select {
                            width: 220px;
                            height: 48px;
                            font-family: $arial;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            display: flex;
                            align-items: center;
                            color: $tertiary;
                            text-indent: 12px;
                            appearance: none;
                            background-color: transparent;
                        }

                        &::after {
                            position: absolute;
                            content: " ";
                            display: block;
                            width: 22px;
                            height: 23px;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(14, 6, 6);
                            transform: rotate(90deg);
                            top: 24%;
                            left: 83%;
                            z-index: -1;
                        }
                    }

                    #com_onglet {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 15px 0px 0px 66px;
                        gap: 63px;
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-transform: uppercase;

                        .onglet.actif {
                            text-decoration: underline;
                            text-underline-offset: 2px;
                        }
                    }
                }

                #orders_by_type {
                    .table_mon_compte {
                        .header {
                            background-color: $black;
                            color: $white;
                            height: 40px;

                            th {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 36px;

                                &:first-child {
                                    padding-left: 16px;
                                }

                                .label-bloc {
                                    display: flex;
                                }
                            }
                        }

                        .rollover {
                            width: 120px;
                            height: 16px;
                            border-bottom: 1px solid $black;

                            &:last-child {
                                border-bottom: 0;
                            }

                            td {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 16px;
                                padding-top: 14px;
                                padding-right: 5px;
                                padding-bottom: 32px;

                                a {
                                    color: $black;
                                    display: block;

                                    &.refund {
                                        padding-top: 5px;
                                    }
                                }

                                &:first-child {
                                    padding-left: 16px;

                                    a {
                                        text-decoration: none;
                                    }
                                }

                                &:nth-child(2) {
                                    a {
                                        text-decoration: none;
                                    }
                                }

                                &:last-child {
                                    a {
                                        margin-right: 17px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// ORDER DETAILS
body.order {
    .wrapper_menu_compte {
        a {
            &:nth-child(3) {
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }
    .lightbox.popup_numero_retour {
        width: 800px;
        height: 662px;
        margin-top: 84px;

        .light_title {
            padding-top: 38px;
        }

        .recommandation {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            width: 435px;
            margin: 17px auto;
        }

        .btn_container.divBtnPrintPdf {
            //display: block !important;
            margin-top: 2.2%;

            .button {
                width: 284px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                padding-top: 17px;
            }
        }

        .returnFormWrapper {

            .selectWrapper {
                margin-top: 24px;
                margin-left: 23px;
                width: calc(50% - 63px);

                .date_select {
                    position: relative;
                    margin-bottom: 16px;
                    width: 100%;
                    background-color: transparent;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 120px;
                        display: inline-block;
                        background-image: url(../svg/spritesheet.svg);
                        transform: rotate(90deg);
                        width: 24px;
                        height: 24px;
                        background-size: 524% auto;
                        background-position: 17% 40%;
                        cursor: pointer;
                        z-index: -1;
                    }

                    &:nth-child(4) {
                        &::after {
                            top: 1px;
                            left: 262px;
                        }
                    }

                    .return_quantity,
                    .return_choice {
                        border-radius: 0px;
                        border: 1px solid $tertiary;
                        padding: 16px 64px 15px 11px;
                        appearance: none;
                        background-color: transparent;
                        color: $tertiary;
                        cursor: pointer;

                        option {
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }

                    .return_quantity {
                        width: 153px;
                    }

                    .return_choice {
                        width: 296px;
                        margin-top: -8px;
                    }
                }
            }
        }

        .returnLIghtboxWrapper {
            display: flex;
            width: 640px;
            margin: 0 auto;
            padding-left: 33px;
            flex-wrap: wrap;
            max-height: 380px;
            
            .lb-wrap {
                padding: 0 !important;

                .lb-content {
                    padding: 0;
                }
            }

            .formWrapper {
                padding-left: 33px;
                margin-top: 9px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;

                .main_title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    padding-bottom: 15px;
                }
            }

            .product_return_wrapper {
                display: flex;
                border-bottom: 1px solid $tertiary;
                width: 100%;
                padding: 29px 0 33px;

                .productReturnListWrapper {
                    display: flex;
                    width: 50%;
                }

                .selectWrapper {
                    span {
                        display: none;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .wrapper_moncompte {
        .left-container {
            .detailCmdTop {
                max-width: 1280px;
                width: 100%;
                margin: -4px auto;
                padding-right: 7px;
                padding-bottom: 4px;

                a {
                    text-decoration: none;
                    color: $black;
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    margin-left: 33px;

                    &::before {
                        content: " ";
                        position: absolute;
                        cursor: pointer;
                        width: 22px;
                        height: 22px;
                        background-color: $black;
                        mask-image: url(../svg/spritesheet.svg);
                        mask-repeat: no-repeat;
                        mask-position: 19.5% 39.5%;
                        mask-size: 551%;
                        transform: rotate(180deg);
                        margin-left: -33px;
                    }
                }
            }

            .wrapper_moncompte_main {
                .wrapper_left {
                    padding-top: 40px;
                    display: flex;
                    flex-wrap: wrap;

                    @media screen and (max-width: 1024px) {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .order_detail_head {
                        display: flex;
                        width: 100%;
                        margin-bottom: -55px;

                        @media screen and (max-width: 1024px) {
                           width: 100%;
                        }

                        .wrapper_infos_cmd {
                            width: 284px;
                            height: 96px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            .header {
                                text-transform: uppercase;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                padding-bottom: 15px;
                            }

                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 16px;
                                font-family: $primaryFont;
                            }

                            .num_suivi {
                                a {
                                    text-decoration: underline;
                                    color: $black;
                                    span {
                                        color: $black;
                                    }
                                }
                            }
                        }

                        .wrapper_delivery_addr {
                            width: 259px;
                            height: 80px;
                            margin-left: 8px;

                            .addr_title {
                                text-transform: uppercase;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                padding-bottom: 16px;
                            }

                            .addr_title_2 {
                                display: none;
                            }

                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 16px;
                                font-family: $primaryFont;
                            }
                        }

                        .cmd_detail_btm {
                            width: 450px;
                            height: 240px;
                            margin-left: 21.9%;

                            @media screen and (max-width: 1024px){
                                width: 30%;
                                margin-left: 13.9%;
                            }
                            
                            .all_product_uneligible {
                                margin-bottom: 15px;
                                font-size: 13px;
                            }

                            .wrap_total_commande {
                                border: 1px solid $tertiary;
                                padding: 34px 39px;
                                box-sizing: border-box;

                                @media screen and (max-width: 1024px) {
                                    padding: 10px 34px 39px;
                                }

                                .titre {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 40px;
                                    line-height: 48px;

                                    @media screen and (max-width: 1024px) {
                                        line-height: normal;
                                        font-size: 32px;
                                    }
                                }

                                .wrapper_total {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 16px;
                                    margin-top: 21px;

                                    .clearfix {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;

                                        &:nth-child(2) {
                                            margin-top: 8px;
                                        }

                                        &:last-child {
                                            margin-top: 25px;
                                            padding-top: 13px;
                                            border-top: 1px solid $black;
                                            font-family: $primaryFont;
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 13px;
                                            line-height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .order_detail_content {
                        max-width: 695px;
                        width: 100%;

                        @media screen and (max-width: 1024px) {
                            width: 65%;
                        }

                        .cmd_items {
                            // display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            // x225 voir si ok en prod avant MEL
                            display: grid;
                            grid-template-columns: repeat(2, 50%);
                            gap: 20px;
                            column-gap: 20px;
                            row-gap: 20px;
                            // x225 voir si ok en prod avant MEL END
                            margin-top: -60px;

                            .head_product_line {
                                display: none;
                            }

                            .cart_product_line {
                                display: flex;

                                .cart_product_desc {
                                    margin-top: 14px;
                                    padding-left: 17px;

                                    .deignation {
                                        font-family: $primaryFont;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 13px;
                                        line-height: 16px;
                                    }

                                    .cart_product_ref {
                                        display: none;
                                    }

                                    .wrapper_cart_product_price {
                                        margin-top: -2px;
                                    }

                                    .details {
                                        font-family: $primaryFont;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 13px;
                                        line-height: 16px;
                                        margin-top: 12px;
                                    }
                                }
                            }
                        }
                    }

                    .wrapper_bottom_cmd {
                        margin-top: 72px;
                        width: 350px;
                        margin-left: 140px;

                        #comment_retourner_cmd {
                            #createNumRetour {
                                .titre {
                                    display: none;
                                }

                                .intro {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 16px;
                                }

                                #liste_motif_retour {
                                    .btn_container {
                                        margin-top: 17px;
                                        width: 214px;
                                    }
                                }

                                .content {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 16px;
                                    margin-top: 10px;

                                    a {
                                        color: $black;
                                    }
                                }

                                #motif_retour_etape {
                                    .retour_numero {
                                        display: none;
                                    }

                                    #printPdf {
                                        .links_comment_retourner_cmd {
                                            display: none;
                                        }
                                    }

                                    .print-return {
                                        margin-bottom: 33px;

                                        .content {
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 13px;
                                            margin-bottom: 16px;
                                        }

                                        #btnPrintPdf {
                                            .btn_container.two {
                                                margin-bottom: 5px;
                                                border: 1px solid black;
                                                width: 210px;
                                                display: flex;

                                                .button {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    background: $white;
                                                    color: $black;
                                                    border: none;
                                                    font-weight: 500;
                                                }

                                                &:nth-of-type(2) {
                                                    display: none;
                                                }
                                            }
                                        }

                                        .btn_container {
                                            border: 1px solid black;
                                            width: 210px;
                                            display: flex;

                                            .button {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                background: $white;
                                                color: $black;
                                                border: none;
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                }
                            }

                            .refund_not_free {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 16px;
                                padding-top: 12.3em;
                            }
                        }
                    }
                }
            }
        }
    }
}

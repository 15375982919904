.wrapper_bandeau.rayon,
.wrapper_bandeau.sscat,
.wrapper_top_category {
    position: relative;
    width: 98%;
    max-width: 1800px;
}

.wrapper_bandeau {

    &.rayon,
    &.sscat {
        left: -0.125rem;
        max-width: 1800px;
        width: 100%;
        margin: 0 auto;

        h1 {
            margin: 0 auto;
            font-size: clamp(25px, fs-vw(35, 1920), 35px);
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1;
        }
    }

    img {
        display: none;
    }
}

.wrapper_top_category {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1800px;
    width: 100%;
    margin: 16px auto 23px;

    @media screen and (max-width: 800px) {
        display: block;
    }

    @media screen and (min-width: 801px) and (max-width: 1024px) {
        display: block;
    }
}

.leftFilter {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding-bottom: 0.5rem;
    }

    a {
        text-decoration: none;
        margin-right: 25px;

        .title {
            position: relative;
            font-size: clamp(11px, fs-vw(13, 1920), 13px);
            font-weight: 500;
            text-transform: uppercase;
            display: inline;
            color: $black;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 110%;
                left: 0;
                right: 100%;
                height: 1px;
                background-color: black;
                transition: right 0.2s ease-in-out;
            }
        }

        &:hover .title:after,
        &.actif .title:after {
            right: 0;
        }
    }
}

.rightFilters {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 3px;
}

#trigger_filtre,
#sort_Trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: clamp(11px, fs-vw(13, 1920), 13px);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.02rem;
    cursor: pointer;

    &:after {
        position: relative;
        top: -0.125rem;
        content: "";
        display: inline-block;
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(45deg);
        margin-left: 8px;
    }
}

#trigger_filtre {
    margin-right: 44px;

    &.triggered+#rayon_sticky_block {
        z-index: 55;
        opacity: 1;
    }
}

#sort_Trigger {
    position: relative;
    right: 2px;

    &.triggered #sort_options {
        z-index: 55;
        opacity: 1;
    }
}

#rayon_sticky_block {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    transition: all 0.3s ease-in-out;
}

#filter_sticky {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 512px;
    width: 100%;
    padding: 53px 0 40px;
    box-shadow: 0px 8px 8px -9px #000000;

    .filter_wrapper {
        margin-left: 58px;

        @media screen and (max-width: 1400px) {
            margin-left: 3.021vw;
        }

        @media screen and (max-width: 1200px) {
            margin-left: 2.021vw;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &#filter_color,
        &#filter_sizes {
            .selected_options {
                column-count: 1;
                display: flex;
                flex-direction: column;
                max-height: 12vh;
                overflow-y: auto;
                overflow-x: hidden;

                &.several {
                    column-count: 2;
                    max-height: 15vh;
                    display: grid;
                    grid-template-columns: 111px 111px;
                    gap: 10px;
                    overflow-y: auto;
                    overflow-x: hidden;

                    @media screen and (max-width: 912px) {
                        display: flex;

                        .form_itm.check_color {
                            padding-right: 0.2rem;
                        }
                    }

                    @media screen and (max-width: 1024px) {
                        max-height: 33vh;
                    }
                }
            }
        }
    }

    .selected_options {
        &::-webkit-scrollbar {
            width: 1px;
            height: 5px;
            background-color: $grey;
        }

        &::-webkit-scrollbar-thumb {
            background: $black;
        }

        &.several {
            &::-webkit-scrollbar {
                width: 1px;
                height: 5px;
                background-color: $grey;
            }

            &::-webkit-scrollbar-thumb {
                background: $black;
            }
        }
    }

    .charact_item {
        margin-bottom: 10px;

        .selected_options {
            display: none;
            max-height: 113px;
            overflow-y: auto;
            overflow-x: hidden;

            .flex_container {
                display: flex;
                flex-direction: column;
                margin-right: 8px;
            }
        }
    }

    .filter_name {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 28px;
    }

    .charact_name {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .btn-filtre-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 27px;

        .btn_container {
            width: 270px;

            &:first-child {
                margin-right: 8px;
            }
        }
    }
}

.filter_wrapper {
    font-size: 13px;

    input[type="checkbox"] {
        display: none;

        +label {
            cursor: pointer;
        }
    }

    &#filter_color {
        input[type="checkbox"] {
            +label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 13px;
                text-transform: lowercase;
                margin-bottom: 16px;
                // commented to display multicolors label properly
                // margin-right: 25px;

                ::first-letter {
                    text-transform: uppercase;
                }

                span {
                    &.colorPoint {
                        display: block;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #000;
                        border-radius: 50%;
                        margin-right: 16px;
                        box-shadow: inset 0 0 0 0 #fff;
                        transition: all 0.2s ease-in-out;

                        @media screen and (max-width: 1024px) {
                            margin-right: 12px;
                        }
                    }

                    &.colorName {
                        position: relative;
                        line-height: 1.2;

                        &::first-letter {
                            text-transform: uppercase;
                        }

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 0;
                            height: 1px;
                            background: #000;
                            transition: width 0.2s ease-in-out;
                        }
                    }
                }
            }

            +label:hover span.colorName:after {
                width: 100%;
            }

            &:checked+label span.colorName {
                font-weight: 500;

                &:after {
                    width: 100%;
                }
            }
        }
    }

    &#filter_sizes,
    &#wrapper_promo_filtre,
    &#filter_caract {
        input[type="checkbox"] {
            +label {
                position: relative;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 13px;
                margin-bottom: 16px;
                line-height: 1.2;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background: #000;
                    transition: width 0.2s ease-in-out;
                    // avoid the underline to have a width > to the element
                    // white-space: nowrap;
                }
            }

            /* Recettage x40 => Filtre sélectionné en gras et non souligné
            + label:hover:after,
            &:checked + label:after {
                width: 100%;
            }
            */

            +label:hover:after {
                width: 100%;
            }

            &:checked+label {
                font-weight: 500;

                &:after {
                    width: 100%;
                }
            }
        }
    }
}

.slider-range-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 210px;

    .slider-range,
    .vue-slider-rail {
        position: relative;
        width: 112px;
        height: 1px;
        background-color: #989898;
        margin: 0 auto;

        .ui-slider-range,
        .vue-slider-process {
            position: absolute;
            top: -1px;
            cursor: pointer;
            height: 3px;
            background-color: black;
        }

        .vue-slider-process {

            &:before,
            &:after {
                display: none;
            }
        }

        .vue-slider-dot {
            content: "";
            display: block;
            position: absolute;
            top: -3px;
            width: 7px !important;
            height: 7px !important;
            border-radius: 50%;
            background: black;
        }

        .ui-slider-handle {
            position: absolute;
            display: block;
            width: 7px;
            height: 7px;
            background: #000;
            border-radius: 50%;
            top: -3px;
            opacity: 0;
            cursor: pointer;
        }
    }

    .priceRangeDisplay {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.6rem;

        div {
            width: 30px;
            text-align: center;
        }
    }
}

#sort_options {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 49px 24px 24px;
    background-color: #fff;

    a {
        font-size: 13px;
        font-weight: 500;
        color: #000;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        span {
            position: relative;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                width: 0;
                height: 1px;
                background: #000;
                transition: width 0.2s ease-in-out;
            }
        }

        &:hover,
        &.actif {
            span:after {
                width: 100%;
            }
        }
    }
}

#list_item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 4px;
    grid-row-gap: 52px;
    width: 98%;
    max-width: 1800px;
    margin: 0 auto;

    .add_wishlist {
        display: none;
    }

    .item {
        min-width: 100% !important;
        max-width: 100% !important;

        .imgWrapper {
            .noSizeAvailable {
                background: $white;
                text-align: center;

                p#size_is_not_available {
                    margin-top: 24px;
                    font-size: 0.875rem;
                }
            }

            .wrap_rolloverproduit {
                .btnAddBasketWrapper {
                    min-height: 64px;
                    margin-top: unset;
                }
            }
        }


        &.doubleColItem {
            .productSwiper {
                &.doubleCol {
                    max-width: 896px;
                }
            }
        }


        /**
         * For double visu
         */
        &:nth-child(22n + 11),
        &:nth-child(22n + 20) {
            //max-width: 820px;

            a.block_lnk {
                max-width: 100%;
                padding-top: calc(596 / 447 * 100%);
            }

            .imgWrapper {
                span.ill_img {
                    padding-top: calc(596 / 447 * 100%);
                }
            }

            .productVisualMulti {
                .swiper-slide {
                    width: 50%;
                }
            }
        }

        &:nth-child(22n + 11) {
            grid-column-start: 3;
            grid-column-end: 5;
        }

        &:nth-child(22n + 20) {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        &.show_push_one_product {
            display: flex !important;
        }

        &.push .video_container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: calc(596 / 447 * 100%);

            iframe {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.loader_scroll {
    display: none;
    position: absolute;
    left: 50%;
    bottom: -49px;
    transform: translateX(-50%);

    span {
        display: block;
        margin: 0 auto;
        width: 120px;
        height: 47px;
        background: transparent url(../svg/three_dots_black.svg) no-repeat center;
        background-size: auto 22%;
    }
}

.pagerNav {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 308px;
    height: 46px;
    margin: 0 auto;
}

.nbProdDisplayed {
    width: 100%;
    height: 100%;

    +.loader {
        width: 100%;
        height: 100%;
    }

    &.loading+.loader {
        z-index: 1;
        opacity: 1;
    }
}

.displayAllProd {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-top: 24px;

    span {
        position: relative;
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: #000;
            transition: left 0.2s ease-in-out;
        }

        &:hover:after {
            left: 100%;
        }

        &.loading {
            display: block;
            font-size: 0;
            background: transparent url(../svg/three_dots_black.svg) no-repeat center;
            background-size: auto 15px;
            width: 100%;
            height: 15px;

            &:after {
                display: none;
            }
        }
    }
}

.page_rayon .txt_bandeau {
    width: 98%;
    max-width: 1440px;
    margin: 80px auto 0;

    .txtWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .title {
        width: 36%;
        padding-right: 60px;
        box-sizing: border-box;
        font-size: 24px;
        font-weight: 500;
    }

    .txt {
        width: 64%;
        max-width: 900px;
        font-size: 13px;
        line-height: 15.6px;

        &.trimed,
        &.full {
            display: none;

            &.displayed {
                display: block;
            }
        }

        .read_more,
        .read_less {
            position: relative;
            width: fit-content;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            margin-top: 24px;
            cursor: pointer;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 125%;
                left: 0;
                right: 0;
                height: 1px;
                background: #000;
                transition: left 0.2s ease-in-out;
            }

            &:hover:after {
                left: 100%;
            }
        }

        h1 {
            font-size: 24px;
            font-weight: 500;
            margin: 1rem 0 0.5rem;
        }

        h2 {
            font-size: 16px;
            font-weight: 600;
            margin: 1rem 0 0.5rem;
        }

        h3 {
            font-size: 13px;
            font-weight: 600;
            margin: 1rem 0 0.5rem;
        }

        a {
            color: $black;
        }

        ul {
            list-style-type: disc;
            padding-left: 30px;
        }
    }
}

#back_to_top {
    background-color: $primaryColor;
    width: 24px;
    height: 24px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    transition: background-color 0.3s, opacity 0.5s;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    &:after {
        content: "";
        display: block;
        border-right: 3px solid #fff;
        border-top: 3px solid #fff;
        width: 5px;
        height: 5px;
        transform: rotate(-45deg);
        position: absolute;
        top: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

body.category.boutique {
    #site_global_wrap.main_wrapper {
        .page_rayon {
            #scroll_items.container_products {
                #list_item.items_wrapper.bydefault {
                    .item .block_push .infos {
                        z-index: 0;
                    }
                }
            }

            #content_obj {
                .container_products {
                    #list_item {

                        .productColorFieldset,
                        .productSizeFieldset {
                            .choices_list {
                                @media screen and (max-width: 1024px) {
                                    flex-wrap: nowrap;
                                }

                                .form_itm {
                                    +.remaining_colors {
                                        @media screen and (max-width: 1024px) {
                                            padding: 0 0.17rem;
                                        }
                                    }
                                }
                            }

                            input[type="radio"] {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.category {
    #content_obj {
        .wrap_rolloverproduit {
            .remaining_colors_link {
                text-decoration: none;
            }

            .remaining_colors {
                width: 29px;
                height: 14px;
                padding: unset;
                color: $black;
            }
        }
    }
}

.page_recherche {
    .selected_options {
        padding: 0 1rem;
    }
}

.category {
    .head_top {
        max-width: 1800px;
    }

    .page_rayon {
        .filtersWrapper {
            max-width: 1800px;
            width: 98%;
            margin: 0 auto;
        }

        .wrap_flitre_pager {
            margin-top: 64px;
        }
    }

    .main_ariane {
        max-width: 1800px;
        width: 100%;
    }
}